@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@300;400;700&display=swap");

body {
  margin: 0;
  font-family: "Kumbh Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Kumbh Sans Regular", source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

ol,
ul {
  list-style: initial;
  padding: revert;
  margin: revert;
}
